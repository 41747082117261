<template>
  <div class="documents pd-20">
    <a-page-header class="mb-10" style="padding: 0" sub-title="">
      <div slot="title">
        <h1>Documentos</h1>
      </div>
      <template slot="extra">
        <a-button
          icon="edit"
          type="primary"
          ghost
          class="travel-btn"
          html-type="submit"
          @click="showModal()"
        >
          Novo Documento
        </a-button>
      </template>
    </a-page-header>

    <a-tabs type="card" default-active-key="active" @change="callback">
      <a-tab-pane key="active" tab="Ativos">
        <a-table
          class="travel-table mt-20"
          :columns="columns"
          :data-source="filterDocs(data, 'Ativo')"
          :loading="loading"
        >
          <a slot="id" slot-scope="text">{{ text }}</a>

          <span class="travel-actions" slot="action" slot-scope="record">
            <a-tooltip placement="top" title="Editar">
              <a class="edit" @click="edit(record.id)">
                <a-icon type="edit-svg" />
              </a>
            </a-tooltip>

            <a-tooltip placement="top" title="Ver">
              <a class="view ml-20" @click="view(record.id)">
                <a-icon slot="prefix" type="eye-svg" />
              </a>
            </a-tooltip>

            <a-popconfirm
              title="Tem certeza que deseja duplicar?"
              ok-text="Sim"
              placement="left"
              cancel-text="Não"
              @confirm="duplicate(record.id)"
            >
              <a-icon slot="icon" type="question-circle-o" />

              <a-tooltip placement="top" title="Duplicar">
                <a class="copy ml-20">
                  <a-icon type="copy" />
                </a>
              </a-tooltip>
            </a-popconfirm>
          </span>
        </a-table>
      </a-tab-pane>
      <a-tab-pane key="deactivated" tab="Desativados" force-render>
        <a-table
          class="travel-table mt-20"
          :columns="columns"
          :data-source="filterDocs(data, 'Desativado')"
          :loading="loading"
        >
          <a slot="id" slot-scope="text">{{ text }}</a>

          <span class="travel-actions" slot="action" slot-scope="record">
            <a-tooltip placement="top" title="Editar">
              <a class="edit" @click="edit(record.id)">
                <a-icon type="edit-svg" />
              </a>
            </a-tooltip>

            <a-tooltip placement="top" title="Ver">
              <a class="view ml-20" @click="view(record.id)">
                <a-icon slot="prefix" type="eye-svg" />
              </a>
            </a-tooltip>

            <a-popconfirm
              title="Tem certeza que deseja duplicar?"
              ok-text="Sim"
              placement="left"
              cancel-text="Não"
              @confirm="duplicate(record.id)"
            >
              <a-icon slot="icon" type="question-circle-o" />

              <a-tooltip placement="top" title="Duplicar">
                <a class="copy ml-20">
                  <a-icon type="copy" />
                </a>
              </a-tooltip>
            </a-popconfirm>
          </span>
        </a-table>
      </a-tab-pane>
    </a-tabs>

    <a-modal
      v-model="modalCreateDocument"
      :footer="null"
      title="NOVO DOCUMENTO"
      @ok="handleOk"
      @cancel="closeModal"
    >
      <a-form v-if="modalCreateDocument" :form="form" @submit="handleSubmit">
        <a-row>
          <a-col :span="24">
            <a-form-item class="mb-10">
              <a-input
                class="travel-input"
                size="large"
                placeholder="Nome do modelo"
                v-decorator="[
                  'name',
                  {
                    rules: [
                      {
                        required: true,
                        message: 'Escreva um nome para o documento',
                      },
                    ],
                  },
                ]"
              >
                <a-icon slot="prefix" type="import" />
              </a-input>
            </a-form-item>
          </a-col>
        </a-row>

        <a-row>
          <a-col class="a-right mt-20" :span="24">
            <a-button
              icon="edit"
              class="travel-btn green"
              :disabled="disableButton"
              html-type="submit"
            >
              Novo documento
            </a-button>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { format, parse } from "date-fns";

export default {
  name: "Documents",
  data() {
    return {
      loading: false,
      data: [],
      companies: [
        { label: "Viajar Resorts", value: "Viajar Resorts" },
        { label: "Voe Simples", value: "Voe Simples" },
      ],
      modalCreateDocument: false,
      documentData: {},
      form: this.$form.createForm(this),
      columns: [
        {
          title: "ID",
          dataIndex: "id",
          key: "id",
          class: "id",
        },
        {
          title: "Nome do documento",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "Empresa",
          dataIndex: "company",
          key: "company",
        },
        {
          title: "Usuário",
          dataIndex: "user",
          key: "user",
        },
        {
          title: "Status",
          dataIndex: "status",
          key: "status",
        },
        {
          title: "Data/Hora",
          dataIndex: "created",
          key: "created",
        },
        {
          title: "",
          key: "action",
          align: "right",
          scopedSlots: { customRender: "action" },
        },
      ],
      theDocument: [],
    };
  },
  beforeMount() {
    this.getDocuments();

    if (this.$store.state.createDocument) {
      this.modalCreateDocument = true;
    }
  },
  methods: {
    filterDocs(documents, status) {
      let filteredDocs = [];

      console.log("filteredDocs", documents, status);

      documents.forEach((doc) => {
        if (doc.status == status) {
          filteredDocs.push(doc);
        }
      });

      console.log(documents, filteredDocs);

      return filteredDocs;
    },
    closeModal() {
      this.modalCreateDocument = false;
      this.$store.state.createDocument = false;
    },
    getDocuments() {
      this.loading = true;
      this.$http
        .get("/document/read")
        .then(({ data }) => {
          this.data = data.data.map((document) => {
            return {
              id: document.id,
              company: document.company,
              user: `${document.user.first_name} ${document.user.last_name} `,
              name: document.name,
              status: document.status == 1 ? "Ativo" : "Desativado",
              created: this.formatDate(document.created),
            };
          });
          this.loading = false;
        })
        .catch(({ res }) => {
          this.$message.error(res.data.message);
          this.loading = false;
        });
    },
    create(contractData) {
      this.$http
        .post("/document/create", contractData)
        .then(({ data }) => {
          this.$router.push(`edit/${data.id}`);
          this.getContracts();
          this.$message.success(data.message);
          this.visible = false;
          this.createdId;
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
        });
    },
    showModal() {
      this.modalCreateDocument = true;
    },
    handleSubmit(e) {
      e.preventDefault();
      this.$store.state.createDocument = false;
      this.form.validateFields((err, values) => {
        values.user = this.$store.state.userData.id;
        console.log(">>>", err, values);
        if (!err) {
          this.create(values);
        }
      });
    },
    confirmDelete(id) {
      this.$http
        .post("/document/delete", {
          id: id,
        })
        .then(({ data }) => {
          this.$message.success(data.message + "!");
          this.getDocuments();
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
        });
    },
    duplicate(id) {
      this.$http
        .post("/document/duplicate", {
          id: id,
          user_id: this.$store.state.userData.id,
        })
        .then(({ data }) => {
          this.$message.success(data.message);
          this.getDocuments();
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
        });
    },
    edit(id) {
      this.$router.push(`edit/${id}`);
    },
    view(id) {
      this.$router.push(`view/${id}`);
    },
    formatDate(date) {
      let theDate = parse(date, "yyyy-MM-dd HH:mm:ss", new Date());
      return format(theDate, "dd/MM/yyyy HH:mm");
    },
  },
};
</script>
